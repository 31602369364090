<template>
  <footer class="fixed-bottom">
    <b-row class="pull-right">
      <b-col style="vertical-align: middle;margin-right: 30px;">
        &copy; Copyright 2022 by <a target="_blank" href="http://streamlinebackoffice.cloud" class="text-decoration-none"><b>Acume</b></a>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>