<template>
  <div class="row manage-user" style="margin-right: 25%;margin-left: 25%">
    <b-row class="col-12 mt-3 mb-1 pr-0">
      <b-col class="col-10">
        <h5>Manage Practice Clients</h5>
      </b-col>
      <b-col class="col-2 p-0">
        <b-row class="align-content-center align-items-center">
          <b-col class="text-right">
            <b-button v-b-modal.link-client class="btn pull-right btn-sm" variant="green" @click="updateComponentStatus"><i class="plus" aria-hidden="true"></i>+ Add New Client</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="col-md-12">
      <div class="overflow-auto">
        <ClientList :clients="clients" :fields="fields" />
        <b-pagination v-if="recordsTotal > 0"
                      v-model="currentPage"
                      :total-rows="recordsTotal"
                      :per-page="perPage"
                      align="center"
                      aria-controls="my-table"
                      size="sm"
        ></b-pagination>
        <p class="text-center">Current Page: {{ currentPage }}</p>
      </div>
    </div>
    <Loader :is-visible="isLoading"/>
    <b-modal id="remove-client" ref="modal" title="Remove Client">
      <p class="my-4">Are You sure you want to remove <b>{{ clientCompanyName }}</b> from this Practice?</p>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="float-left" variant="blue" @click="$bvModal.hide('remove-client')" size="sm">Cancel</b-button>
          <b-button type="button" variant="green" class="float-right" @click="deleteClient(clientId)" size="sm">Remove</b-button>
        </div>
      </template>
    </b-modal>
    <LinkExsistingClientModel v-if="showLinkExsistingClientModelComponent"/>
    <LinkNewClientModel v-if="showLinkNewClientModelComponent" :passedSelectedClient="selectedClient" :passedUsers="practiceUsers" :passFilteredSelectedUser="filterSelectedUser" :isEdit="true" ref="updateClientModel"/>
    <Loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import LinkExsistingClientModel from "@/components/LinkExsistingClientModel.vue";
import ClientList from "@/views/ClientList";
import LinkNewClientModel from "@/components/LinkNewClientModel";

export default {
  name: "ManagePracticeClient",
  components: {
    LinkExsistingClientModel,
    Loader,
    ClientList,
    LinkNewClientModel
  },
  provide: function () {
    return {
      sortingChanged: this.sortingChanged,
      deleteClientBefore: this.deleteClientBefore,
      getClients: this.getClients,
    }
  },
  data() {
    return {
      isLoading: false,
      clients: [],
      sortBy: localStorage.getItem('sortBy'),
      sortDesc: JSON.parse(localStorage.getItem('sortDesc')),
      perPage: 20,
      currentPage: 1,
      totalPage: 1,
      recordsTotal:0,
      recordsFiltered:0,
      fields: [
        {
          key: "client_name",
          label: "Your Acume Clients",
          sortable: true,
          width: '11110px'
        },
        {
          key: "users",
          label: "Users",
          sortable: true,
          formatter: "capitalizeText",
          tdClass:'className',
        },
        {key: "action", label: "", class: 'text-nowrap'}
      ],
      clientId: 0,
      clientCompanyName: '',
      practiceUsers: [],
      selectedClient: '',
      filterSelectedUser : [],
      showLinkExsistingClientModelComponent: true,
      showLinkNewClientModelComponent: false
    }
  },
  watch:{
    currentPage: function (newValue) {
      this.currentPage = newValue;
      this.getClients();
    },
  },
  computed: {},
  methods: {
    sortingChanged(ctx) {
      localStorage.setItem('sortDesc', ctx.sortDesc)
      localStorage.setItem('sortBy', ctx.sortBy)
    },
    getClients() {
      let currentObj = this;
      currentObj.isLoading = true;
      console.log(currentObj.currentPage,currentObj.perPage);
      axios.get("/practice_clients?p="+currentObj.currentPage+"&r="+currentObj.perPage)
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.clients = response.data.body.data;
            currentObj.recordsTotal = response.data.body.recordsTotal;
            currentObj.recordsFiltered = response.data.body.recordsFiltered;
            currentObj.perPage = response.data.body.rowsPerPage;
            currentObj.currentPage = response.data.body.page;
            currentObj.totalPage = response.data.body.totalPage;
            if(currentObj.clients.length > 0) {
              let users;
              (currentObj.clients).forEach(function (value, index, array) {
                users = [];
                (value.user_ids).forEach(function (userId) {
                  let temp = (currentObj.practiceUsers).filter(function (item) {
                    return item.user_id == userId;
                  })
                  if (temp.length > 0) {
                    users.push(temp[0].name);
                  }
                });
                currentObj.clients[index]['users'] = users
              });
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.responseFail(error);
          });
    },
    deleteClient(clientId) {
      let currentObj = this;
      currentObj.$bvModal.hide('remove-client')
      currentObj.isLoading = true;
      axios.delete("/delete_practice_client", {
        data: {
          client_id: clientId
        }
      })
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.success = response.data.status;
            if (currentObj.success != true) {
              currentObj.responseFail(response.data.message);
            } else {
              currentObj.responseSuccess(response.data.message);
              currentObj.getClients();
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.responseFail(error);
          });

    },
    deleteClientBefore(clientId, clientName) {
      this.clientId = clientId;
      this.clientCompanyName = clientName;
    },
    pageReload() {
      this.$router.go();
    },
    getPracticeUsers() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.get("/users")
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.practiceUsers = response.data.body.data;
            currentObj.getClients();
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.responseFail(error);
          });
    },
    editClientBefore(client) {
      this.isLoading = true;
      this.filterSelectedUser=[];
      this.selectedClient = client.client_id;
      this.showLinkNewClientModelComponent = true;
      this.showLinkExsistingClientModelComponent = false;
      let currentObj = this;
      (client.user_ids).forEach(function (userId) {
        let temp = (currentObj.practiceUsers).filter(function (item) {
          return item.user_id == userId;
        })
        if (temp.length > 0) {
          temp.forEach(function (value, index, array) {
            temp[index]['client_id'] = client.client_id;
          });
          currentObj.filterSelectedUser.push(temp[0]);
        }
      })
      currentObj.isLoading = false;
      setTimeout(function () {
        currentObj.$bvModal.show('link-new-client');
      },1000);
    },
    updateComponentStatus() {
      this.showLinkNewClientModelComponent = false;
      this.showLinkExsistingClientModelComponent = true;
    }
  },
  mounted() {
    this.getPracticeUsers();
  }
}
</script>

<style scoped>
/deep/ .table > tbody > tr > td.className {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>