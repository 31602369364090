<template>
  <div class="po-header">
    <div class="row">
      <div class="col-md-12">
        <h5 style="font-weight:bold">Admin Dashboard</h5>
      </div>
    </div>
    <div class="row mb-6" v-if="checkUserPermission('manage-practice-details') || checkUserPermission('manage-practice-clients')">
      <div class="col-md-6 offset-3">
        <label style="font-weight: bold;">Practice Settings</label>
        <p style="width:66.66%">Manage Your Practice profile and linked client account</p>
        <div class="row">
          <div class="col-md-4" v-if="checkUserPermission('manage-practice-details')">
            <div class="card text-center">
              <b-link to="/practice-details" style="text-decoration: none;">
                <div class="card-header">
                  <b style="color: #000;"> Details</b>
                </div>
                <div class="card-body">
                  <font-awesome-icon icon="fa-solid fa-info" style="width: 50px; height: 50px; color: black;"/>
                </div>
              </b-link>
            </div>
          </div>
          <div class="col-md-4" v-if="checkUserPermission('manage-practice-clients')">
            <div class="card text-center">
              <b-link to="/manage-practice-client" style="text-decoration: none;">
                <div class="card-header">
                  <b style="color: #000;"> Manage Practice Client</b>
                </div>
                <div class="card-body">
                  <font-awesome-icon icon="fa-solid fa-user-friends" style="width: 50px; height: 50px; color: black;"/>
                </div>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-6" v-if="checkUserPermission('manage-notifications')">
      <div class="col-md-6 offset-3">
        <label style="font-weight: bold;">Notifications</label>
        <p style="width:66.66%">Manage Notification templates</p>
        <div class="row">
          <div class="col-md-4" v-if="checkUserPermission('manage-notifications')">
            <div class="card text-center">
              <b-link to="/practice-notification-email" style="text-decoration: none;">
                <div class="card-header">
                  <b style="color: #000;">Notifications</b>
                </div>
                <div class="card-body">
                  <font-awesome-icon icon="fa-solid fa-tasks" style="width: 50px; height: 50px; color: black;"/>
                </div>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-6" v-if="checkUserPermission('manage-users') || checkUserPermission('manage-roles')">
      <div class="col-md-6 offset-3">
        <label style="font-weight: bold;">Users</label>
        <p style="width:66.66%">Create, edit Users and manage User Roles and Permissions</p>
        <div class="row">
          <div class="col-md-4" v-if="checkUserPermission('manage-users')">
            <div class="card text-center">
              <b-link to="/manage-practice-user" style="text-decoration: none;">
                <div class="card-header">
                  <b style="color: #000;">Manage Users</b>
                </div>
                <div class="card-body">
                  <font-awesome-icon icon="fa-solid fa-users" style="width: 50px; height: 50px; color: black;"/>
                </div>
              </b-link>
            </div>
          </div>
          <div class="col-md-4" v-if="checkUserPermission('manage-roles')">
            <div class="card text-center">
              <b-link to="/manage-practice-role" style="text-decoration: none;">
                <div class="card-header">
                  <b style="color: #000;">Manage Roles</b>
                </div>
                <div class="card-body">
                  <font-awesome-icon icon="fa-solid fa-random" style="width: 50px; height: 50px; color: black;"/>
                </div>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "PracticeSettings",
  inject: [],
  components: {
    Loader
  },

  data() {
    return {
      isLoading: false,
      success: false,
      email_template_list: {},
      connected_sys: null
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  padding: 0.75rem 1rem;
}

.form-group span {
  width: 100%;
}

.fa-icon {
  width: auto;
  height: 1em; /* or any other relative font sizes */

  /* You would have to include the following two lines to make this work in Safari */
  max-width: 100%;
  max-height: 100%;
  color: #212529;
}

.fa-icon:hover {
  color: #444;
}
</style>
