<template>
  <div>
    <b-modal id="link-client" button-size="sm" size="xl" ref="link-client" title="Link Existing Acume Clients" @show="clearFilter();openModal()" @hidden="clearFilter" no-stacking  no-close-on-backdrop no-close-on-esc>
      <div class="row manage-user">
        <b-col md="8">
          <b-form-group
              id="input-group-1"
              label="Search"
              label-for="input-1"
              class="bold"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="input-1"
                  v-model="filterText"
                  type="text"
                  placeholder="Type here..."
                  @keydown.enter="getFiltered"
              ></b-form-input>
              <b-input-group-append>
                <b-button class="btn-sm" style="margin-left: 20px;" @click="getFiltered">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <div class="col-md-12">
          <div class="overflow-auto">
            <b-table
                fixed
                head-variant="dark"
                id="my-table"
                :items="clients"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                :filter="filter"
                show-empty
            >
              <template v-slot:cell(c_id)="row">
                <input type="radio" name="selectedClient" id="selectedClient" :value="row.item" v-model="selectedClient">
              </template>

              <template v-slot:cell(emails)="row">
                <span :title="row.item.emails.join(', ')" style="cursor:pointer;">{{row.item.emails.join(', ')}}</span>
              </template>
            </b-table>

            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                aria-controls="my-table"
                size="sm"
            ></b-pagination>
          </div>
        </div>
        <!-- Loader -->
        <Loader :is-visible="isLoading" />
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="pull-left" size="sm" variant="blue" @click="$bvModal.hide('link-client')">Cancel</b-button>
          <b-button v-b-modal.link-new-client size="sm" variant="green" class="pull-right" :disabled="selectedClient == ''">Continue</b-button>
        </div>
      </template>
    </b-modal>
    <LinkNewClientModel :passedSelectedClient="selectedClient" :passedUsers="practiceUsers" :passFilteredSelectedUser="filterSelectedUser" :isEdit="false"/>
    <!-- Loader -->
    <Loader :is-visible="isLoading" />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import LinkNewClientModel from "@/components/LinkNewClientModel.vue";

export default {
  name: 'LinkExsistingClientModel',
  components: {
    Loader,
    LinkNewClientModel
  },
  watch: {
    selectedClient: function (newCLient) {
      let curr = this;
      this.filterSelectedUser=[];
      (newCLient.emails).forEach(function (email) {
        let temp = (curr.practiceUsers).filter(function (item) {
          return (item.email).toLowerCase() == email.toLowerCase()
        })
        if (temp.length > 0) {
          temp.forEach(function (value, index, array) {
            temp[index]['client_id'] = newCLient.c_id;
          });
          curr.filterSelectedUser.push(temp[0]);
        }
      })
    }
  },
  data() {
    return {
      modal_title: "",
      items: [],
      fields: [
        {key: 'c_organisation_name', label: 'Client Name', formatter: "capitalizeText",sortable: true, class: 'text-nowrap'},
        {key: 'nzbn', label: 'ABN/NZBN', formatter: "capitalizeText",sortable: true, class: 'text-nowrap'},
        {key: 'emails', label: 'Accessible By', formatter: "capitalizeText", tdClass:'className'},
        {key: 'c_id', label: 'Select', class: 'text-center', formatter: "capitalizeText"},
      ],
      pageOptions: [20, 40, 60],
      clients: [],
      selectedClient: '',
      practiceUsers: [],
      filterSelectedUser : [],
      perPage: 5,
      currentPage: 1,
      users: [],
      isLoading: false,
      success: false,
      filter: null,
      filterText: null,
      totalRows: 0,
      sortBy: localStorage.getItem('sortBy'),
      sortDesc: JSON.parse(localStorage.getItem('sortDesc')),
    }
  },
  methods: {
    getClients() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.get("/clients")
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.clients = response.data.body;
            currentObj.totalRows = response.data.body.length;
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    getPracticeUsers() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.get("/users")
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.practiceUsers = response.data.body.data;
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    onFiltered(filteredItems)
    {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearFilter() {
      this.filter = '';
      this.filterText = '';
    },
    openModal() {
      this.getClients();
      this.getPracticeUsers();
    },
    getFiltered() {
      this.filter = this.filterText;
    }
  }
}
</script>

<style scoped>
/deep/ .table > tbody > tr > td.className {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>