<template>
  <div>
    <table class="table table-borderless" style="margin-top: 10px;">
      <tr>
        <td style="font-weight: bold; font-size:20px;">
          {{ role.name }} Permission
        </td>
        <td>
          <b-button variant="outline-yellow" size="sm" class="ml-2 pull-right" @click="fetchRole(role.id, $event)">Edit Role</b-button>
          <b-button variant="orange" size="sm" class="ml-2 pull-right" :disabled="role.name == 'Admin'" @click="deleteRole(role.id)">Delete Role</b-button>
        </td>
      </tr>
    </table>
    <hr>
    <table class="table table-borderless">
      <tbody>
      <tr>
        <td style="font-weight:bold; font-size:15px;"><label>Practice Admin</label></td>
      </tr>
      <template v-for="task in tasks">
        <tr v-if="task.st_name == 'manage-practice-details' || task.st_name == 'manage-practice-clients' || task.st_name == 'manage-notifications' || task.st_name == 'manage-users' || task.st_name == 'manage-roles'" :key="task.st_id">
          <td style="">
            <label>{{ task.st_title }}</label>
          </td>
          <td style="" class="pull-right">
            <b-button v-if="task.checked" @click="saveTask(task.st_id, id, false)" :variant="(role.name != 'Admin' && (task.st_name == 'manage-users' || task.st_name == 'manage-roles') ) ? 'outline-secondary' : 'outline-green'" size="sm" :disabled="role.name != 'Admin' && (task.st_name == 'manage-users'|| task.st_name == 'manage-roles')">Active</b-button>
            <b-button v-else @click="saveTask(task.st_id, id, true)" :variant="(role.name != 'Admin' && (task.st_name == 'manage-users' ||task.st_name == 'manage-roles') ) ? 'outline-secondary' : 'outline-orange'" size="sm" :disabled="role.name != 'Admin' && (task.st_name == 'manage-users'|| task.st_name == 'manage-roles') ">Inactive</b-button>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["passed_tasks", "id", "selected_tasks", "role"],
  components: {},
  inject: ["addUpdateTask", "fetchRole", "deleteRole"],
  methods:
      {
        saveTask(task_id, role_id, val) {
          let is_selected = val; //event.target.checked
          this.addUpdateTask(task_id, role_id, is_selected);
        }
      },
  computed: {
    tasks() {
      let found = false;
      let retTasks = [];
      let ths = this;
      this.passed_tasks.forEach((task, i) => {
        retTasks.push(task);
        if (ths.selected_tasks.length > 0) {
          found = false;
          ths.selected_tasks.forEach(t => {
            if (t.task_id == task.st_id) {
              found = true;
            }
          });
          if (found) {
            retTasks[i]["checked"] = true;
          } else {
            retTasks[i]["checked"] = false;
          }
        } else {
          retTasks[i]["checked"] = false;
        }
      });
      return retTasks;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>