<template>
  <div class="row po-header">
    <div class="col-md-6 offset-3 mt-3">
      <b-card
          :header="title"
          header-tag="header"
          header-bg-variant="dark-header"
          header-text-variant="white"
          bg-variant="light"
          style="min-height:350px;"
      >
        <b-card-text>
          <form @submit="savePracticeDetail">
            <div class="form-group row">
              <label for="tradingName" class="col-sm-4 col-form-label">Trading Name<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="tradingName"
                    v-model="tradingName"
                    placeholder="Trading Name"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="fullLegalName" class="col-sm-4 col-form-label">Full Legal Name<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="fullLegalName"
                    v-model="fullLegalName"
                    placeholder="Full Formal Name"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="addressLine1" class="col-sm-4 col-form-label">Address Line 1<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="addressLine1"
                    v-model="addressLine1"
                    placeholder="Address Line 1"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="addressLine2" class="col-sm-4 col-form-label">Address Line 2</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="addressLine2"
                    v-model="addressLine2"
                    placeholder="Address Line 2"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="addressLine3" class="col-sm-4 col-form-label">Address Line 3</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="addressLine3"
                    v-model="addressLine3"
                    placeholder="Address Line 3"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="city" class="col-sm-4 col-form-label">City<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="city"
                    v-model="city"
                    placeholder="City"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="postcode" class="col-sm-4 col-form-label">Postal Code (Zone)<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="postcode"
                    v-model="postcode"
                    placeholder="Postal Code (Zone)"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="suburb" class="col-sm-4 col-form-label">Suburb<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="suburb"
                    v-model="suburb"
                    placeholder="Subdivision"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="identifierScheme" class="col-sm-4 col-form-label">Identifier Scheme<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <select v-model="identifierScheme" class="form-control" id="identifierScheme" required>
                  <option value="" selected>Please select Identifier Scheme</option>
                  <option
                      v-for="address_scheme in address_schemes"
                      :value="address_scheme.value"
                      :key="address_scheme.value"
                  >{{ address_scheme.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="legalRegistrationIdentifier" class="col-sm-4 col-form-label">Legal Registration Identifier<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="legalRegistrationIdentifier"
                    v-model="legalRegistrationIdentifier"
                    placeholder="Legal Registration Identifier"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="countryId" class="col-sm-4 col-form-label">Country<span class="text-danger"> *</span></label>
              <div class="col-sm-8">
                <select v-model="countryId" class="form-control" id="countryId" required>
                  <option value="" selected>Please select Country</option>
                  <option
                      v-for="role in countries"
                      :value="role.id"
                      :key="role.id"
                  >{{ role.code }} - {{ role.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="logo" class="col-sm-4 col-form-label">Logo</label>
              <div class="col-sm-8">
                <input class="form-control" type="file" id="formFile">
              </div>
            </div>

            <div class="form-group row mt-3">
              <div class="col-6">
                <b-button size="sm" to="/settings" variant="blue" class="mr-1 pull-left">Back</b-button>
              </div>
              <div class="col-6">
                <b-button type="submit" size="sm" variant="green" class="pull-right">Update</b-button>
              </div>
            </div>
          </form>
        </b-card-text>
      </b-card>
      <!-- Loader -->
      <Loader :is-visible="isLoading"/>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "AddNewCompany",
  components: {
    Loader
  },
  data() {
    return {
      isLoading: false,
      tradingName: '',
      fullLegalName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      postcode: '',
      suburb: '',
      additional_legal_info: '',
      identifierScheme: '',
      gst_reg_no: '',
      other_reg: '',
      logo: '',
      countryId: '',
      message: '',
      success: false,
      alert_box: '',
      title: "Practice Details",
      userId: '',
      countries: [],
      tax_scheme_id: "GST",
      cutomization_id: "urn:cen.eu:en16931:2017#conformant#urn:fdc:peppol.eu:2017:poacc:billing:international:aunz:3.0",
      profile_id: "urn:fdc:peppol.eu:2017:poacc:billing:01:1.0",
      legalRegistrationIdentifier: 13,
      ozedi_level: 'SENDING_ONLY',
      address_schemes: [{value:"NZBN"},{value:"ABN"}],
      ozedi_client_id: "",
      ozedi_receiver_client_id: "",
      db_data: []
    };
  },
  methods: {
    savePracticeDetail(e) {
      e.preventDefault();
      let currentObj = this;
      currentObj.isLoading = true;
      axios.put("/update_practice", {
        organisation_name: currentObj.tradingName,
        full_legal_name: currentObj.fullLegalName,
        address_line_1: currentObj.addressLine1,
        address_line_2: currentObj.addressLine2,
        address_line_3: currentObj.addressLine3,
        city: currentObj.city,
        country_id: currentObj.countryId,
        postcode: currentObj.postcode,
        suburb: currentObj.suburb,
        identifier_scheme: currentObj.identifierScheme,
        legal_registration_identifier: currentObj.legalRegistrationIdentifier,
        logo: currentObj.logo
      })
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.success = response.data.status;
            if (currentObj.success !== true) {
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
              currentObj.pageRedirect();
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },

    // onFileChanged (event) {
    //     this.logo = event.target.files[0]
    // },

    getPracticeDetail() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.get("/practice").then(function (response) {
        currentObj.success = response.data.status;
        if (currentObj.success !== true) {
          currentObj.isLoading = false;
          currentObj.$notify({
            title: "Error",
            text: response.data.message,
            type: "error"
          });
        } else {
          if (response.data.body != null) {
            currentObj.db_data = response.data.body,
                currentObj.tradingName = (response.data.body.organisation_name == null || response.data.body.organisation_name == 'null') ? "" : response.data.body.organisation_name,
                currentObj.fullLegalName = (response.data.body.full_legal_name == null || response.data.body.full_legal_name == 'null') ? "" : response.data.body.full_legal_name,
                currentObj.addressLine1 = (response.data.body.address_line_1 == null || response.data.body.address_line_1 == 'null') ? "" : response.data.body.address_line_1,
                currentObj.addressLine2 = (response.data.body.address_line_2 == null || response.data.body.address_line_2 == 'null') ? "" : response.data.body.address_line_2,
                currentObj.addressLine3 = (response.data.body.address_line_3 == null || response.data.body.address_line_3 == 'null') ? "" : response.data.body.address_line_3,
                currentObj.city = (response.data.body.city == null || response.data.body.city == 'null') ? "" : response.data.body.city,
                currentObj.postcode = (response.data.body.postcode == null || response.data.body.postcode == 'null') ? "" : response.data.body.postcode,
                currentObj.suburb = (response.data.body.suburb == null || response.data.body.suburb == 'null') ? "" : response.data.body.suburb,
                currentObj.countryId = (response.data.body.country_id == null || response.data.body.country_id == 'null' || response.data.body.country_id == 0) ? "" : response.data.body.country_id,
                currentObj.legalRegistrationIdentifier = (response.data.body.legal_registration_identifier == null || response.data.body.legal_registration_identifier == 'null') ? "" : response.data.body.legal_registration_identifier,
                currentObj.logo = (response.data.body.logo == null || response.data.body.logo == 'null') ? "" : response.data.body.logo,
                currentObj.identifierScheme = (response.data.body.identifier_scheme == null || response.data.body.identifier_scheme == 'null') ? "" : response.data.body.identifier_scheme
          }
          currentObj.isLoading = false;
        }
      })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },

    getCountryList() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.get("/countries").then(function (response) {
        currentObj.countries = response.data.body.data;
        currentObj.isLoading = false;
      })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },

    pageRedirect() {
        setTimeout(
            () =>
                this.$router.push({
                    path: "/settings"
                }),
            1000
        );
    }
  },
  mounted() {
    this.getPracticeDetail();
    setTimeout(() => {
    }, 1000);

    this.getCountryList();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>