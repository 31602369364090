<template>
    <b-table
        fixed
        head-variant="dark"
        id="my-table"
        :items="clients"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="$parent.sortBy"
        :sort-desc.sync="$parent.sortDesc"
        @sort-changed="sortingChanged"
        show-empty
    >

      <template v-slot:cell(users)="row">
        <span :title="row.item.users.join(', ')" style="cursor:pointer;">{{row.item.users.join(', ')}}</span>
      </template>
      <template v-slot:cell(action)="row">
        <div class="pull-right">
          <b-button size="sm" variant="blue" @click="$parent.editClientBefore(row.item)">Edit</b-button>
          <b-button class="btn ml-1 btn-sm" variant="danger" v-b-modal.remove-client @click="deleteClientBefore(row.item.client_id,row.item.client_name)">Remove</b-button>
        </div>
      </template>
    </b-table>
</template>

<script>
export default {
  name: "ClientList",
  props: ['clients', 'fields', 'sortBy', 'sortDesc', 'perPage', 'currentPage', 'totalPage', 'recordsTotal', 'recordsFiltered', 'loggedInUserEmail'],
  inject:['sortingChanged','deleteClientBefore'],
}
</script>

<style scoped>

</style>