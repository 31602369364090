import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from "../views/RegisterView.vue";
import PracticeDashboard from "../views/PracticeDashboard.vue"
import PracticeSetting from "../views/PracticeSettings.vue"
import ManagePracticeClient from "../views/ManagePracticeClient.vue"
import ManagePracticeUser from "../views/ManagePracticeUser.vue"
import AddPracticeUser from "../views/AddPracticeUser.vue"
import ManagePracticeRole from "../views/ManagePracticeRole.vue"
import PracticeDetails from "../views/PracticeDetails.vue"
import PracticeNotificationEmail from "../views/PracticeNotificationEmail.vue"
import AddPractice from "../views/AddPractice.vue"
import UserVerification from "../views/UserVerification.vue"
import Profile from "../views/Profile.vue";
import NotFound from "../views/NotFound.vue";
import Callback from "../views/Callback.vue";
import InvitationStatus from "../views/InvitationStatus.vue";
import AccessForbidden from "../views/AccessForbidden.vue";
import {authGuard} from '../auth/authGuard';
import ForgotPassword from "@/views/ForgotPassword.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/forgot-password',
        name: 'forgotpassword',
        component: ForgotPassword,
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView
    },
    {
        path: '/',
        name: 'home',
        component: PracticeDashboard,
        beforeEnter: authGuard,
    },
    {
        path: "/callback",
        name: "callback",
        component: Callback
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        beforeEnter: authGuard,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: PracticeDashboard,
        beforeEnter: authGuard,

    },
    {
        path: '/settings',
        name: 'settings',
        component: PracticeSetting,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: [
                "manage-practice-clients",
                "manage-users",
                "manage-roles",
                "manage-notifications",
                "manage-practice-details",
            ],
        }
    },
    {
        path: '/manage-practice-client',
        name: 'manage-practice-client',
        component: ManagePracticeClient,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-practice-clients"],
        }
    },
    {
        path: '/manage-practice-user',
        name: 'manage-practice-user',
        component: ManagePracticeUser,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-users"],
        }
    },
    {
        path: '/add-practice-user',
        name: 'add-practice-user',
        component: AddPracticeUser,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-users"],
        }
    },
    {
        path: '/manage-practice-role',
        name: 'manage-practice-role',
        component: ManagePracticeRole,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-roles"],
        }
    },
    {
        path: '/practice-details',
        name: 'practice-details',
        component: PracticeDetails,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-practice-details"],
        }
    },
    {
        path: '/practice-notification-email',
        name: 'practice-notification-email',
        component: PracticeNotificationEmail,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-notifications"],
        }
    },
    {
        path: '/add-practice',
        name: 'add-practice',
        component: AddPractice,
        beforeEnter: authGuard,
    },
    {
        path: '/edit-practice-user/:id',
        name: 'editpracticeuser',
        component: AddPracticeUser,
        beforeEnter: authGuard,
        meta: {
            requiresPermission: true,
            permissionKey: ["manage-users"],
        }
    },
    {
        path: '/user-verification/:token',
        name: 'userverification',
        component: UserVerification,
        beforeEnter: authGuard,

    },
    {
        path: '/client_invitation/:status/:uuid',
        name: 'invitationstatus',
        component: InvitationStatus,

    },
    {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: NotFound,
    },
    {
        path: '/access-forbidden',
        name: 'accessforbidden',
        component: AccessForbidden,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    axios.interceptors.response.use(
        response => {
            if ((response.data.message).toLowerCase() == 'invalid token' && response.data.status == false){
                Vue.dialog.alert('Logging out due to multiple login or session timeout.').then(function() {
                    localStorage.clear();
                    sessionStorage.clear();
                    router.go()
                });
            }
            else {
                return response
            }
        },
        function (error) {
        }
    )

    const permissionRequired = to.matched.some((route) => route.meta.requiresPermission)
    const permission_Key = to.meta.permissionKey;
    const authed = localStorage.getItem('token')
    if (authed && permissionRequired) {
        if (checkUserPermission(permission_Key)) {
            next();
        } else {
            next({name: 'accessforbidden'})
        }
    } else {
        next();
    }
})

function checkUserPermission(permission_Key) {
    let userPermissions = localStorage.getItem('role_permissions');
    userPermissions = JSON.parse(userPermissions);
    let matched = false;
    if(userPermissions != ""){
        if (permission_Key.length > 0) {
            var userPermissionsData = userPermissions.map(function(obj) {
                return obj.st_name;
            })
            permission_Key.forEach(t => {
                if (userPermissionsData.indexOf(t) != -1) {
                    matched = true;
                }
            });
        }
    }

    return matched;
}

export default router
