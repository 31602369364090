<template>
  <b-navbar id="nav" toggleable="lg" type="dark" class="header-navbar">
    <b-navbar-brand to="/" style="height: 60px!important;" class="p-0">
      <img alt="eInvoicing" src="../assets/acume-small-logo.png">
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/settings" right v-if="$auth.isAuthenticated" class="orgname">Practice Admin</b-nav-item>
        <b-nav-item right v-if="$auth.isAuthenticated" class="orgname">{{ (organizationName) }}</b-nav-item>
        <b-nav-item-dropdown right v-if="$auth.isAuthenticated">
          <template v-slot:button-content>
            <em class="user-profile">{{ $auth.user.name }}</em>
          </template>
          <b-dropdown-item to="/profile">Profile</b-dropdown-item>
          <b-dropdown-item @click.prevent="logout">Log out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Vue from "vue";

export default {
  name: "Header",
  data(){
    return {
      user:JSON.parse(localStorage.getItem('user')),
      isAuthenticated:Vue.prototype.$auth.isAuthenticated,
      organizationName: JSON.parse(localStorage.getItem('user')).organisation_name
    }
  },
  methods: {
    // Log the user out
    logout() {
      localStorage.clear();
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
}
</script>

<style scoped>
.navbar {
  background: #1a1d24;
}

.nav-item {
  border-radius: 5px;
}

.nav-item.orgname a {
  color: #ffffff;
  font-weight: bold;
}
</style>