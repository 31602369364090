<template>
  <div class="pologmodel m-0 p-0">
    <b-modal id="modal-log-detail" size="lg" title="Add New Client" hide-footer no-stacking  no-close-on-backdrop no-close-on-esc>
      <div style="text-align:center; padding: 40px;">
        <div style="display: inline-block; padding-right: 50px;">
          <a :href="url" target="_blank" class="btn btn-green btn-sm" style="width: 220px;">Create New Account</a>
        </div>
        Or
        <div style="display: inline-block; padding-left: 50px;">
          <b-button class="btn-sm" variant="green" style="width: 220px;" v-b-modal.link-client>Link Existing</b-button>
        </div>
      </div>
    </b-modal>
    <LinkExsistingClientModel/>
  </div>
</template>

<script>
import LinkExsistingClientModel from "@/components/LinkExsistingClientModel.vue";
import {VUE_ACUME_URL} from "../../auth_config.json"

export default {
  name: 'AddClientModal',
  components: {
    LinkExsistingClientModel
  },
  computed: {},
  data() {
    return {
      modal_title: "",
      url: VUE_ACUME_URL + "/#/signup"
    }
  },
  methods: {}
}
</script>

<style>

</style>