<template>
  <div>
    <div class="container-fluid bg-grey">
      <div style="height: 120px;">&nbsp;</div>
      <div class="row col-12 pr-5 pl-5">
        <div class="col-md-8">
          <div class="row">
            <div class="col-sm-2">
              <img src="../assets/acume-small-reverse.png">
            </div>
            <div class="col-sm-10">
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div v-if="email_sent == true">
            <b-card header="featured" header-tag="header">
              <h4 slot="header" type="dark" variant="dark">MESSAGE</h4>
              <b-card-body class="text-center">
                <p>Your password reset email was sent to</p>
                <p><b>{{ email }}</b></p>
                <p>Please check your inbox and spam folder and follow the directions to reset your password.</p>
                <p><b>Didn't receive it?</b></p>
                <div class="row">
                  <div class="col-md-6">
                    <b-btn href="https://www.streamlinebusiness.net/contact/" class="btn btn-grey btn-block">Contact Us</b-btn>
                  </div>
                  <div class="col-md-6">
                    <b-btn @click="pageReload" class="btn btn-grey btn-block">Try Again</b-btn>
                  </div>
                </div>
              </b-card-body>
              <b-card-footer type="dark" variant="dark">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <router-link to="/">Back to Login</router-link>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </div>
          <div v-if="email_sent == false">
            <b-card header="featured" header-tag="header">
              <h4 slot="header" type="dark" variant="dark">FORGOT PASSWORD</h4>
              <b-card-body>
                <form @submit="changePassword">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" v-model="email" id="email" class="form-control" placeholder="Email" required="required">
                  </div>
                  <div class="form-group">
                    <button class="btn btn-grey btn-block">Submit</button>
                  </div>
                </form>
              </b-card-body>
              <b-card-footer type="dark" variant="dark">
                <div class="row">
                  <div class="col-md-12 text-center">
                    <router-link to="/">Back to Login</router-link>
                  </div>
                </div>
              </b-card-footer>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :is-visible="isLoading"/>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import {VUE_APP_API_SERVER_URL} from "../../auth_config.json";

export default {
  components: {
    Loader
  },
  data() {
    return {
      isLoading: false,
      email: null,
      email_sent: false,
      success: false
    }
  },
  methods: {
    changePassword(e) {
      e.preventDefault();
      let currentObj = this;
      currentObj.isLoading = true;
      // To call api
      axios.post(VUE_APP_API_SERVER_URL+"/changepassword", {
        "email": currentObj.email,
      })
          .then(function (response) {
            // To check response status is success or fail
            currentObj.isLoading = false;
            if (response.data.status == false) {
              // To return fail response
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              // To return success response
              currentObj.email_sent = true;
            }
          })
          .catch(function (error) {
            // To return fail response
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    pageReload() {
      this.$router.go();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>