<template>
  <div class="row po-header mt-3">
    <div class="col-md-6 offset-3">
      <b-card
          :header="title"
          header-tag="header"
          header-bg-variant="dark-header"
          header-text-variant="white"
          bg-variant="light"
          style="min-height:350px;"
      >
        <b-card-text>
          <form @submit="addnewuser">
            <div class="form-group row">
              <label for="firstName" class="col-sm-4 col-form-label">First Name</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    v-model="firstName"
                    placeholder="First Name"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="lastName" class="col-sm-4 col-form-label">Last Name</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    v-model="lastName"
                    placeholder="Last Name"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="phone" class="col-sm-4 col-form-label">Phone Number</label>
              <div class="col-sm-8">
                <input
                    type="number"
                    class="form-control"
                    id="phone"
                    v-model="phone"
                    placeholder="Phone Number"
                    @keypress="isNumber($event)"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="user_type" class="col-sm-4 col-form-label">User Role</label>
              <div class="col-sm-8">
                <select v-model="userRole" class="form-control" id="user_type" required>
                  <option value="" selected>Please select User Role</option>
                  <option
                      v-for="role in roles"
                      :value="role.id"
                      :key="role.id"
                  >{{ role.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col-sm-4 col-form-label">Email</label>
              <div class="col-sm-8">
                <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="email"
                    placeholder="Email"
                    :readonly="userId > 0"
                    :class="(userId > 0) ? 'custom-read-only':''"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="defaultContact" class="col-sm-4 col-form-label">Default Contact Point</label>
              <div class="col-sm-8">
                <input
                    type="checkbox"
                    class=""
                    id="defaultContact"
                    v-model="defaultContact"
                    placeholder=""
                    :checked="defaultContact"
                    @click="boxDisable($event);"
                    ref="defaultContactSelected"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12">
                <b-button
                    size="sm"
                    to="/manage-practice-user"
                    variant="blue"
                    class="pull-left"
                >Back
                </b-button>
                <b-button
                    v-if="userId !=null"
                    size="sm"
                    type="submit"
                    variant="green"
                    class="pull-right"
                >Update
                </b-button>
                <b-button v-else size="sm" type="submit" variant="green" class="pull-right">Save</b-button>
              </div>
            </div>
          </form>
        </b-card-text>
      </b-card>
      <!-- Loader -->
      <Loader :is-visible="isLoading"/>
    </div>
    <div>
      <b-modal id="modal-2" title="Are you sure?" ok-title="Continue" @ok="setDefaultContact()" @cancel="checkFunction()" @close="onClose">
        <p class="my-4">Only one user can be the default contact point, by clicking Continue this user will become the default contact point.
          <br><br>
          <span style="margin-left:30%;">Would you like to continue?</span>
        </p>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "admin",
  components: {
    Loader
  },
  data() {
    return {
      isLoading: false,
      email: null,
      firstName: null,
      lastName: null,
      message: null,
      success: false,
      alert_box: null,
      title: "Add New User",
      userId: null,
      roles: [],
      userRole: "",
      phone: null,
      company_list: [],
      defaultContact: false,
      loggedInUser:JSON.parse(localStorage.getItem('user'))
    };
  },
  methods: {
    onClose() {
      let currentObj = this;
      currentObj.defaultContact = 0;
    },

    boxDisable(event) {
      let currentObj = this;
      if(this.$refs.defaultContactSelected.checked == true) {
        this.$refs.defaultContactSelected.checked = false;
        this.$root.$emit('bv::show::modal', 'modal-2', event.target);
      } else {
        currentObj.responseFail('At least one contact point must be set.To change the contact point tick the box on the replacement users profile.');
        currentObj.defaultContact = 1;
      }
    },
    setDefaultContact()
    {
      let currentObj = this;
      currentObj.defaultContact = 1;
    },

    checkFunction()
    {
      let currentObj = this;
      currentObj.defaultContact = 0;
    },
    addnewuser(e) {
      e.preventDefault();
      let currentObj = this;
      currentObj.isLoading = true;
      if (currentObj.userId != null) {
        currentObj.updateUser();
            }
      else {
        axios.post("/create_user", {
          first_name: currentObj.firstName,
          last_name: currentObj.lastName,
          name: currentObj.firstName + " " + currentObj.lastName,
          phone_number: currentObj.phone,
          role_id: currentObj.userRole,
          email: currentObj.email,
          is_default: (currentObj.defaultContact) ? "1" : "0",
        })
            .then(function (response) {
              currentObj.isLoading = false;
              currentObj.success = response.data.status;
              if (currentObj.success != true) {
                currentObj.responseFail(response.data.message);
              } else {
                // To return success response and redirect to user list page
                currentObj.responseSuccess(response.data.message);
                currentObj.pageRedirect();
              }
            })
            .catch(function (error) {
              currentObj.isLoading = false;
              currentObj.responseFail(error);
            });
      }
    },
    getUserDetail(id) {
      let currentObj = this;
      currentObj.isLoading = true;
      currentObj.title = "Edit User";
      axios.post("/user", {user_id: id}).then(function (response) {
        currentObj.success = response.data.status;
        if (currentObj.success !== true) {
          currentObj.isLoading = false;
          currentObj.responseFail(response.data.message);
        } else {
          currentObj.firstName = response.data.body.first_name;
          currentObj.lastName = response.data.body.last_name;
          currentObj.name = response.data.body.name;
          currentObj.phone = response.data.body.phone_number;
          currentObj.userRole = response.data.body.role_id;
          currentObj.email = response.data.body.email;
          currentObj.defaultContact = (response.data.body.is_default == "1") ? true : false;
          currentObj.isLoading = false;
        }
      })
          .catch(function (error) {
            currentObj.responseFail(error);
          });
    },
    updateUser() {
      let currentObj = this;
      currentObj.isLoading = true;
      currentObj.title = "Edit User";
      axios
          .put("/users/" + currentObj.userId, {
            first_name: currentObj.firstName,
            last_name: currentObj.lastName,
            name: currentObj.firstName + " " + currentObj.lastName,
            phone_number: currentObj.phone,
            role_id: currentObj.userRole,
            email: currentObj.email,
            is_default: (currentObj.defaultContact) ? "1" : "0",
          })
          .then(function (response) {
            currentObj.success = response.data.status;
            currentObj.isLoading = false;
            if (currentObj.success !== true) {
              currentObj.responseFail(response.data.message);
            } else {
              if (parseInt(currentObj.userId) == parseInt(currentObj.loggedInUser.id)) {
                currentObj.loggedInUser.first_name = response.data.body.first_name;
                currentObj.loggedInUser.last_name = response.data.body.last_name;
                currentObj.loggedInUser.name = response.data.body.name;
                currentObj.loggedInUser.role_id = response.data.body.role_id;
                currentObj.loggedInUser.task = response.data.body.tasks;
                localStorage.setItem('user', JSON.stringify(currentObj.loggedInUser));
                localStorage.setItem('role_permissions', JSON.stringify(response.data.body.tasks));
              }
              currentObj.responseSuccess(response.data.message);
              setTimeout(() => {
                currentObj.pageRedirect();
              }, 2000);
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.responseFail(error);
          });
    },
    fetchRoles() {
      let ths = this;
      ths.isLoading = true;
      this.$store.dispatch("fetch_roles", {}).then(res => {
        ths.roles = ths.$store.getters.roles;
        ths.isLoading = false;
      });
    },
    pageRedirect() {
      setTimeout(() => this.$router.push('/manage-practice-user'), 1000);
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    let currentObj = this;
    var id = currentObj.$route.params.id;
    var routeName = currentObj.$route.name;
    if (id > 0 && routeName == "editpracticeuser") {
      currentObj.isLoading = true;
      currentObj.userId = id;
      this.getUserDetail(id);
      setTimeout(() => {
        currentObj.isLoading = false;
      }, 1000);
    }
    this.fetchRoles();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>