<template>
  <div class="container-fluid bg-grey d-flex p-5">
    <div class="col-8" style="align-self: center">
      <img src="../assets/acume-small-reverse.png">
    </div>
    <div class="col-4" style="align-self: center">
      <b-card title="" header-tag="header">
        <template #header>
          <h4 slot="header" type="dark" variant="dark">SET YOUR PASSWORD</h4>
        </template>
        <b-card-body class="landing-card pl-0 pr-0">
          <form @submit="setPassword">
            <div class="form-group">
              <label>Password</label>
              <input
                  type="password"
                  v-model="password"
                  id="password"
                  class="form-control"
                  placeholder="password"
                  required="required"
              />
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <input
                  type="password"
                  v-model="password_confirmation"
                  class="form-control"
                  placeholder="Confirm Password"
                  required="required"
              />
            </div>
            <div class="form-group text-center">
              <b-button class="mt-1 col-12" variant="grey" block @click="onSubmit()">Set Password</b-button>
            </div>
          </form>
        </b-card-body>
        <b-card-footer type="dark" variant="dark">
          <div class="row">
            <div class="col-md-12 text-center">
              <router-link to="/">Back to Login</router-link>
            </div>
          </div>
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Loader
  },
  data() {
    return {
      isLoading: false,
      password: null,
      password_confirmation: null,
      success: false,
      alert_box: null
    };
  },
  methods: {
    onSubmit() {
      this.$router.push({
        path: "/"
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>