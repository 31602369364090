<template>
  <div class="row notification-emails">
    <b-row class="col-12 mt-3 mb-1">
      <b-col class="col-10">
        <h5>Notification Emails</h5>
      </b-col>
    </b-row>
    <div class="col-md-12">
      <div>
        <form>
          <div v-if="allEmailTemplate.length > 0" class="table-responsive">
            <table class="table table-condensed">
              <thead class="thead-dark">
              <tr class="table-row">
                <th>Actions & Conditions</th>
                <th>Subject</th>
                <th>Recipient</th>
                <th>Edit Email</th>
                <th></th>
                <th>Frequency</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(email_template, index) in allEmailTemplate" :key="`A-${index}`">
                <td>{{email_template.template_name}}</td>
                <td>{{email_template.template_subject}}</td>
                <td>{{email_template.template_type}}</td>
                <td>
                  <b-button @click="getEmailTemplateDetail(email_template.id)" variant="secondary" size="sm" v-b-modal.modal-email-template>Edit</b-button>
                </td>
                <td v-if="email_template.status == 0">
                  <b-button @click="updateEmailTempateStatus(email_template.id, '1')" variant="dark" size="sm">Don't Send</b-button>
                </td>
                <td>When</td>
                <td><b-button variant="orange" size="sm">{{email_template.frequency}}</b-button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </div>
    <component :is="emailtemplatemodal" :emailTemplateDetail="this.emailTemplate"/>
    <Loader :is-visible="isLoading" />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import EmailTemplateModal from "@/components/EmailTemplateModal.vue";
import {mapState, mapGetters, mapActions, mapMutations} from "vuex"
export default {
  name: "notificationemails",
  components: {
    Loader,
    EmailTemplateModal
  },
  data() {
    return {
      isLoading: false,
      email_template_list: {},
      email_template_detail: "",
      success: false,
      emailtemplatemodal: "EmailTemplateModal",
    }
  },

  computed:{
    ...mapGetters(["allEmailTemplate", "emailTemplate"])
  },

  created() {
    this.fetchEmailTemplates();
  },

  methods: {
    ...mapActions(["fetchEmailTemplates", "fetchEmailTemplateDetail", "updateEmailStatus"]),

    getEmailTemplateDetail(id){
      let currentObj = this;
      const params = {
        id: id,
      }
      currentObj.fetchEmailTemplateDetail(params);
    },

    updateEmailTempateStatus(id, status){
      let currentObj = this;
      currentObj.isLoading = true;
      const params = {
        id: id,
        status: status
      }
      currentObj.updateEmailStatus(params).then(res => {
        currentObj.isLoading = false;
        if (res.data.status == true)
        {
          currentObj.$notify({
            group: 'alert',
            title: 'Successful',
            text: res.data.message,
            type: 'success'
          });
          currentObj.fetchEmailTemplates();
        }
        else
        {
          currentObj.$notify({
            title: "Error",
            text: res.data.message,
            type: "error"
          });
        }
      }).catch(err=>{
        currentObj.isLoading = false;
        currentObj.$notify({
          title: "Error",
          text: err,
          type: "error"
        });
      });
    },
  },
}
</script>

<style scoped>
.form-heading {
  background-color: #1a1d24 !important;
  color: #fff !important;
  font-weight: bold;
}
.bg-table {
  background: #ccc;
  color: #000;
}
.custom-btn{
  padding: 3px 27px;
}
</style>