<template>
  <div class="row manage-user">
    <b-row class="col-12 mt-3 mb-1">
      <b-col class="col-10">
        <h5>Manage Practice Users</h5>
      </b-col>
    </b-row>
    <div class="col-md-12">
      <div class="overflow-auto">
        <UserList :users="users" :fields="fields" :sortDesc="sortDesc" />
        <div class="text-center mb-3">
          <b-button size="sm" to="/add-practice-user" variant="green">Add New User</b-button>
        </div>
        <b-pagination v-if="recordsTotal > 0"
            v-model="currentPage"
            :total-rows="recordsTotal"
            :per-page="perPage"
            align="center"
            aria-controls="my-table"
            size="sm"
        ></b-pagination>
        <p class="text-center">Current Page: {{ currentPage }}</p>
      </div>
    </div>
    <!-- Loader -->
    <Loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import UserList from "@/views/UserList";

export default {
  name: "ManageUser",
  components: {
    Loader,
    UserList
  },
  provide: function () {
    return {
      sortingChanged: this.sortingChanged,
      deleteUser: this.deleteUser,
    }
  },
  data() {
    return {
      users: [],
      isLoading: false,
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "role_name",
          label: "Role",
          formatter: "capitalizeText",
          sortable: true
        },
        {
          key: "added_at",
          label: "Added at",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {key: "action", label: "Action"}
      ],
      success: false,
      sortBy: localStorage.getItem('sortBy'),
      sortDesc: JSON.parse(localStorage.getItem('sortDesc')),
      perPage: 20,
      currentPage: 1,
      totalPage: 1,
      recordsTotal:0,
      recordsFiltered:0,
      loggedInUserEmail:JSON.parse(localStorage.getItem('user')).email
    };
  },
  watch:{
    currentPage: function (newValue) {
      this.currentPage = newValue;
      this.getClientUsers();
    },
  },
  methods: {
    sortingChanged(ctx) {
      localStorage.setItem('sortDesc', ctx.sortDesc)
      localStorage.setItem('sortBy', ctx.sortBy)
    },
    getClientUsers() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.get("/users?p="+currentObj.currentPage+"&r="+currentObj.perPage)
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.users = response.data.body.data;
            currentObj.recordsTotal = response.data.body.recordsTotal;
            currentObj.recordsFiltered = response.data.body.recordsFiltered;
            currentObj.perPage = response.data.body.rowsPerPage;
            currentObj.currentPage = response.data.body.page;
            currentObj.totalPage = response.data.body.totalPage;
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    deleteUser(id) {
      this.$dialog
        .confirm("If you delete this record, it'll be gone forever.", {
          loader: true
        })
        .then(dialog => {
          dialog.close();
      let currentObj = this;
      currentObj.isLoading = true;
      axios
          .delete("/users/del_user_link/" + id)
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.success = response.data.status;
            if (currentObj.success != true) {
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
              currentObj.getClientUsers();
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
      })
      .catch(() => {
        console.log("Delete aborted");
      });
    }
  },
  mounted() {
    this.getClientUsers();
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>