<template>
  <div class="row manage-user">
    <div class="col-md-12">
      <div class="overflow-auto">
        <b-table
          head-variant="dark"
          id="my-table"
          :items="users"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:cell(action)="row">
            <b-button
              variant="blue"
              size="sm"
              :to="{ path: '/admin/edit-user/'+row.item.id}"
              class="mr-1"
            >Edit</b-button>
            <b-button
              variant="orange"
              size="sm"
              @click="deleteUser(row.item.user_id, row.item.user_role_id)"
              class="mr-1"
            >Delete</b-button>
          </template>
        </b-table>
        <div class="text-center mb-3">
          <b-button size="sm" @click="openModal($event)" variant="green">Add New User</b-button>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="center"
          aria-controls="my-table"
        ></b-pagination>
        <p class="text-center">Current Page: {{ currentPage }}</p>
      </div>
    </div>
    <b-modal id="add-new-practice" ref="modal" :title="modal_title" @ok="" ok-variant="blue" ok-title="Add" size="lg">
            <p class="my-4">Enter a practice's details below to request addition to their client list</p>
            <div class="row">
                <div class="col-md-12">
                    <label style="width: 200px;">Pactice Name</label>
                    <input style="width: 300px;" name="">
                </div>
                <div class="col-md-12">
                    <label style="width: 200px;">Pactice ABN/NZBN</label>
                    <input style="width: 300px;" name="">
                </div>
            </div>
        </b-modal>
    <!-- Loader -->
    <Loader :is-visible="isLoading" />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  name: "ManageUser",
  components: {
    Loader
  },
  data() {
    return {
      client_id: localStorage.getItem("clientID"),
      perPage: 20,
      currentPage: 1,
      users: [],
      isLoading: false,
      modal_title :'',
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true 
        },
        {
          key: "user.email",
          label: "Email",
          sortable: true 
        },
        {
          key: "role.cr_name",
          label: "Role",
          formatter: "capitalizeText",
          sortable: true 
        },
        {
          key: "user_type",
          label: "Type",
          formatter: "capitalizeText",
          sortable: true 
        },
        {
          key: "created_at",
          label: "Added at",
          sortable: true 
        },
        {
          key: "status",
          label: "Status",
          formatter: (value, key, item) => {return value==1 ? 'Active' : 'Inactive'},
          sortable: true 
        },
        { key: "action", label: "Action" }
      ],
      success: false
    };
  },
  methods: {
    openModal(event)
        {
            this.modal_title = 'Add New Practice';
            this.$root.$emit('bv::show::modal', 'add-new-practice', event.target);
        },
  },
  mounted() {
    this.getClientUsers();
  },
  computed: {
    rows() {
      return this.users.length;
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>