<template>
  <div class="access-forbidden">
      <div class="row">
        <div class="col-md-12">
            <div class="error-template" align="center">
                <h1>Oops!</h1>
                <h2>403  Acces Forbidden</h2>
                <div class="error-details">NOT THIS TIME, ACCESS FORBIDDEN</div>
                <div class="error-actions">
                    <b-button to="/" variant="info" class="mt-5">Take Me Home</b-button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "accessforbidden",
}
</script>

<style scoped>

</style>