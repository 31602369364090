<template>
  <div class="row manage-user">
    <b-row class="col-12 mt-3 p-0">
      <b-col class="pull-right p-0">
        <b-row>
          <b-col>
            <b-button class="btn pull-right btn-sm" v-b-modal.modal-log-detail variant="green">+ Add New Client</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="col-md-12">
      <div class="overflow-auto">
        <b-table
            responsive
            head-variant="dark"
            :items="clients"
            :fields="fields"
            show-empty
        >
          <template #head(c_organisation_name)="data">
            <span class="text-nowrap">{{ data.label }}</span>
          </template>
          <template v-slot:cell(c_organisation_name)="row">
            <span class="text-nowrap"><b>{{ row.item.c_organisation_name }}</b></span>
          </template>

          <template v-slot:cell(last_accessed_by)="row">
          <span class="text-nowrap" v-if="(row.item.status).toLowerCase() == 'pending'"><b>Pending Client Approval: </b>
            <span>Invite sent {{ row.item.last_accessed_by }}</span>
          </span>
            <span class="text-nowrap" v-else><b>Last Accessed: </b>
            <span>{{ row.item.last_accessed_at }} <span v-if="row.item.last_accessed_by != null">({{ row.item.last_accessed_by }})</span></span>
          </span>
          </template>
          <template v-slot:cell(status)="row">
          <span class="text-nowrap ">
            <font-awesome-icon icon="fa-solid fa-circle-xmark" :style="{ color: 'red' }" size="3x" v-if="row.item.warningStatus"/>
            <font-awesome-icon icon="fa-solid fa-exclamation-circle" :style="{ color: 'orange' }" size="3x" v-else-if="row.item.informationStatus"/>
            <font-awesome-icon icon="fa-solid fa-check-circle" :style="{ color: 'green' }" size="3x" v-else-if="row.item.okStatus"/>
            <font-awesome-icon icon="fa-solid fa-minus-circle" :style="{ color: 'grey' }" size="3x" v-else-if="row.item.pendingStatus"/>
          </span>
          </template>

          <template #head(inbound_invoice_count)="data">
            <span class="">{{ data.label }}</span>
          </template>
          <template v-slot:cell(inbound_invoice_count)="row">
            <span class="text-nowrap "><b>{{ (row.item.inbound_invoice_count > 0) ? row.item.inbound_invoice_count : '-' }}</b></span>
          </template>

          <template #head(outbound_invoice_count)="data">
            <span class="">{{ data.label }}</span>
          </template>
          <template v-slot:cell(outbound_invoice_count)="row">
            <span class="text-nowrap "><b>{{ (row.item.outbound_invoice_count > 0) ? row.item.outbound_invoice_count : '-' }}</b></span>
          </template>

          <template #head(inbound_order_count)="data">
            <span class="">{{ data.label }}</span>
          </template>
          <template v-slot:cell(inbound_order_count)="row">
            <span class="text-nowrap "><b>{{ (row.item.inbound_order_count > 0) ? row.item.inbound_order_count : '-' }}</b></span>
          </template>

          <template #head(outbound_order_count)="data">
            <span class="">{{ data.label }}</span>
          </template>
          <template v-slot:cell(outbound_order_count)="row">
            <span class="text-nowrap "><b>{{ (row.item.outbound_order_count > 0) ? row.item.outbound_order_count : '-' }}</b></span>
          </template>

          <template #head(accounting_system)="data">
            <span class="">{{ data.label }}</span>
          </template>
          <template v-slot:cell(accounting_system)="row">
            <a href="https://app.myob.com" target="_blank" v-if="(row.item.accounting_system != null && (row.item.accounting_system).toLowerCase()) == 'myob'">
              <img alt="myob" src="../assets/myob.png" style="height: 50px;width: auto;" >
            </a>
            <a href="https://login.xero.com" target="_blank" v-else-if="(row.item.accounting_system != null && (row.item.accounting_system).toLowerCase()) == 'xero'">
              <img alt="xero" src="../assets/xero-logo-blue.png" style="height: 78px;width: auto;">
            </a>
            <span v-else>-</span>
          </template>

          <template v-slot:cell(action)="row">
            <b-button class="btn ml-1 pull-right btn-sm text-nowrap" variant="blue" @click="resendInvitation(row.item.client_id)" v-if="(row.item.status).toLowerCase() == 'pending'">Resend Invite</b-button>
            <a class="btn ml-1 pull-right btn-sm text-nowrap btn-blue" v-else target="_blank" @click="generateAcumeUrl(row.item.client_id)">Go to Acume</a>
          </template>
        </b-table>
      </div>
    </div>
    <AddClientModal/>
    <!-- Loader -->
    <Loader :is-visible="isLoading"/>
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import AddClientModal from "@/components/AddClientModal.vue";
import axios from "axios";
import {VUE_ACUME_URL} from '../../auth_config.json'
import CryptoJS  from 'crypto-js';
import { enc } from 'crypto-js';
import Vue from "vue";
import VueCookies from 'vue-cookies'

export default {
  name: "PracticeDashboard",
  components: {
    Loader,
    AddClientModal
  },
  data() {
    return {
      isLoading: false,
      clients: [],
      fields: [
        {
          key: "c_organisation_name",
          label: "Your Acume Clients",
        },
        {
          key: "last_accessed_by",
          label: "",
        },
        {
          key: "status",
          label: "Status",
          class: 'text-center'
        },
        {
          key: "inbound_invoice_count",
          label: "Inbound Invoices To Process",
          class: 'text-center'
        },
        {
          key: "outbound_invoice_count",
          label: "Outbound Invoices To Process",
          class: 'text-center'
        },
        {
          key: "outbound_order_count",
          label: "Outbound Orders To Process",
          class: 'text-center'
        },
        {
          key: "accounting_system",
          label: "Accounting System",
          class: 'text-center p-0'
        },
        {key: "action", label: ""}
      ],
      acumeUrl:VUE_ACUME_URL,
      user:JSON.parse(localStorage.getItem('user'))
    }
  },
  provide: function () {
    return {
      getClients: this.getClients,
    }
  },
  methods: {
    getClients() {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
      axios.get("/dashboard")
          .then(function (response) {
            currentObj.isLoading = false;
            currentObj.success = response.data.status;
            if (currentObj.success != true) {
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
              currentObj.clients = response.data.result;
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    resendInvitation(clientId) {
      let currentObj = this;
      currentObj.isLoading = true;
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
      axios.post("/approval/client_invitation/resend/" + clientId)
          .then(function (response) {
            currentObj.isLoading = false;
            if (response.data.status == false) {
              // To return fail response
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              // To return success response
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    generateAcumeUrl(clientId) {
      this.$cookies.remove("url",'/','acumelimited.com');

      var data = {'clientId': clientId,'userEmail':this.user.email};

      // Encrypt
      var encryptedClientId = CryptoJS.AES.encrypt(JSON.stringify(data), 'secretPassphrase').toString();
      encryptedClientId = encodeURIComponent(encryptedClientId.toString());
      this.$cookies.set("url", encryptedClientId);
      window.open(this.acumeUrl+'/#/auto-login',"_blank");
    }
  },
  mounted() {
    this.getClients();
  }
}
</script>
<style type="text/css">
.radius {
  border-radius: 15px !important;
}
.table td {
   vertical-align: middle !important;
}
.table thead th {
  vertical-align: middle !important;
  border-bottom: 2px solid #dee2e6;
}
</style>
