import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store/index'
import {VUE_APP_API_SERVER_URL,VUE_APP_AUTH0_DOMAIN,VUE_APP_AUTH0_CLIENT_ID,VUE_APP_AUTH0_AUDIENCE,VUE_ACUME_URL} from "../auth_config.json";
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import axios from 'axios';
window.axios = axios
const token = localStorage.getItem('token');
axios.defaults.baseURL = VUE_APP_API_SERVER_URL;
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}
axios.defaults.headers.post['Content-Type'] = 'application/json';
import VueAxios from 'vue-axios'
Vue.use(VueAxios,axios)

import'./assets/style.css'
import'./assets/acume-style.css'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import functions from "./helpers/functions"
Vue.mixin(functions)

//Font awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faCircleXmark, faExclamationCircle, faCheckCircle, faMinusCircle, faFileInvoiceDollar, faBuilding, faTasks, faUsers, faRandom,faInfo,faUserFriends,faUserCircle} from '@fortawesome/free-solid-svg-icons'
library.add(faCircleXmark, faExclamationCircle, faCheckCircle, faMinusCircle, faFileInvoiceDollar, faBuilding, faTasks, faUsers, faRandom,faInfo,faUserFriends,faUserCircle)
Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.use(Vuex)

import VueCookies from 'vue-cookies'
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: '1d', domain:'acumelimited.com'})

import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
Vue.use(VuejsDialog);

// Import the Auth0 configuration
import { VUE_APP_AUTH0_DOMAIN as domain, VUE_APP_AUTH0_CLIENT_ID as clientId, VUE_APP_AUTH0_CALLBACK_URL } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  redirect_uri:VUE_APP_AUTH0_CALLBACK_URL
});

export const Bus = new Vue();
// export const auth0 = Vue.prototype.$auth

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
