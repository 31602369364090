
import Axios from 'axios'

const state = {
    email_template: [],
    email_template_detail: {}
};

const getters = {
    allEmailTemplate: state=>state.email_template,
    emailTemplate: state=>state.email_template_detail
};

const actions = {
    fetchEmailTemplates({ commit }) {        
        return Axios.get('/notifications')
        .then(res => {
            commit('setEmailTemplates', res.data.body.data);
        }).catch(err => {
            console.log("Error when attempt to fetch email template....");
            console.log(err);
        })
    },

    fetchEmailTemplateDetail({ commit }, query_param) {        
        return Axios.get(`/notifications/${query_param.id}`)
        .then(res => {
            commit('setEmailTemplateDetail', res.data.body);
        }).catch(err => {
            console.log("Error when attempt to fetch email template detail....");
            console.log(err);
        })
    },

    updateEmailStatus({ commit }, query_param) {        
        return Axios.put(`/email-template/${query_param.id}`, {
            status: query_param.status
        })
    },

    updateEmail({ commit }, query_param) {
        return Axios.put(`/notifications/${query_param.id}`, {
            template_subject: query_param.template_subject,
            template_body: query_param.template_body
        })
    },

    importEmailTemplate({ commit }){
        let currentObj = this;
        axios.get("/get-email-template")
        .then(function(response) {
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    
};

const mutations = {
    setEmailTemplates: (state, emailTemplates) => (state.email_template = emailTemplates),
    setEmailTemplateDetail: (state, emailTemplateDetail) => (state.email_template_detail = emailTemplateDetail),
};

export default {
    state,
    getters,
    mutations,
    actions
}