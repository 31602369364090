//Store for entities and related methods
import Axios from 'axios'


//state variables for entities
const state = {
    tasks: [],
    roles: []
};

const getters = {
    roles: state => {
        return state.roles;
    },
    tasks: state => {
        return state.tasks;
    }
};

const mutations = {
    set_roles_data(state, results) {
        state.roles = [];
        try {

            state.roles = results;
        } catch (error) {
            throw error;
        }

    },
    set_tasks_data(state, results) {
        state.tasks = [];
        try {
            state.tasks = results;
        } catch (error) {
            throw error;
        }

    }
};

const actions = {
    fetch_tasks({commit}, query_param) {
        return Axios.get('/tasks', {})
            .then(res => {

                commit('set_tasks_data', res.data.body.data);
            }).catch(err => {
                console.log("Error when attempt to fetch tasks....");
                console.log(err);
            })
    },
    fetch_roles({commit}, query_param) {
        return Axios.get('/roles', query_param)
            .then(res => {

                commit('set_roles_data', res.data.body.data);
            }).catch(err => {
                console.log("Error when attempt to fetch roles....");
                console.log(err);
            })
    },
    save_role({commit}, query_param) {
        return Axios.post('/add_role', query_param);
    },
    update_role({commit}, query_param) {
        return Axios.put("/roles/" + query_param.role_id, {
            "name": query_param.name,
            "description": query_param.description,
            "task_ids": query_param.task_ids
        });
    },
    delete_role({commit}, query_param) {
        return Axios.delete("/roles/" + query_param);
    },
    add_task({commit}, query_param) {
        return Axios.put("/roles/" + query_param.role_id + "/" + query_param.task_id + "/" + query_param.selected);
    },
    fetch_role({commit}, query_param) {
        return Axios.post('/role', query_param);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}