<template>
  <div>
    <b-modal id="link-new-client" button-size="sm" size="lg" ref="link-new-client" title="Link Existing Acume Clients" @show="setFilterUserData" no-stacking  no-close-on-backdrop no-close-on-esc>
      <div class="row col-12 manage-user">
        <div class="font-weight-lighter">
          <div class="mb-4">
            <span class="bold">Add {{ isEdit ? '/ Remove' : '' }} Users</span>
            <br>
            <span>Choose from the Practice user list to add / remove users from the Existing Client files. NB: All users are added with the 'Admin' role by default.</span>
            <br>
          </div>
          <div class="mb-4">
            <v-select class="border-0" :options="passedUsers" label="name" placeholder="Select User" v-model="FilteredSelectedUser" multiple taggable @option:deselected="deselectUser($event)">
              <template v-slot:option="option">
                <span></span>
                {{ option.first_name }} {{ option.last_name }} ({{ option.email }})
              </template>
            </v-select>
          </div>
          <div class="d-flex mt-3" style="align-items: center;">
            <div class="bold">Override Client Approval</div>
            <b-form-checkbox v-model="checked" name="check-button" size="lg" switch  :disabled="isEdit"></b-form-checkbox>
          </div>
          <span class="font-weight-lighter">Turn this setting on to add the selected files to the Practice without requiring the Client's approval. When not enabled, the Acume Contact Points will be asked for approval before enabling these accounts to be displayed on your Practice Dashboard.</span>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button class="pull-left" size="sm" variant="blue" @click="$bvModal.hide('link-new-client')">Cancel</b-button>
          <b-button variant="green" size="sm" class="pull-right" disabled v-if="spinner">
            Loading...
            <b-spinner small></b-spinner>
          </b-button>
          <b-button variant="green" size="sm" class="pull-right" @click="isEdit ? addOrDeleteUserFromAcume(passedSelectedClient) : addClientToPractice()" v-else>{{ isEdit ? 'Update Client' : 'Add New Clients' }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'LinkNewClientModel',
  props: ["passedSelectedClient", "passedUsers", "passFilteredSelectedUser","isEdit"],
  inject:{'getClients':{default:[]}},
  data() {
    return {
      users: this.passedUsers,
      selectedClient: this.passedSelectedClient,
      FilteredSelectedUser: this.passFilteredSelectedUser,
      modal_title: "",
      isLoading: false,
      items: [],
      fields: [
        {key: 'cc_customer_name', label: 'Client Name', formatter: "capitalizeText"},
        {key: 'cc_nzbn_no', label: 'ABN/NZBN', formatter: "capitalizeText"},
        {key: 'cc_tax_id', label: 'Accessible By', formatter: "capitalizeText"},
        {key: 'cc_abn_or_nzbn', label: 'Select', class: 'text-center', formatter: "capitalizeText"},
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60],
      filter: null,
      selected_tax_exemption_reason_code: '',
      checked: true,
      spinner: false,
      deleteSelectedUser: []
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-log-detail');
      this.$bvModal.hide('link-client');
      this.$bvModal.hide('link-new-client');
    },
    addClientToPractice() {
      let currentObj = this;
      if((currentObj.FilteredSelectedUser).length == 0) {
        currentObj.responseFail('Please select user.');
        return;
      }
      currentObj.isLoading = true;
      currentObj.spinner = true;
      axios
          .post("/add_practice_client", {
            client_id: currentObj.passedSelectedClient.c_id,
            client_name: currentObj.passedSelectedClient.c_organisation_name,
            client_data: {
              nzbn: currentObj.passedSelectedClient.nzbn,
              contact_name: currentObj.FilteredSelectedUser.first_name,
              contact_email: currentObj.FilteredSelectedUser.last_name,
            },
            status: (currentObj.checked) ? "approved" : "pending",
            is_override_approval: (currentObj.checked) ? 1 : 0,
          })
          .then(function (response) {
            currentObj.success = response.data.status;
            currentObj.isLoading = false;
            if (currentObj.success !== true) {
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
              currentObj.spinner = false;
              currentObj.closeModal();
            } else {
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
              currentObj.addOrDeleteUserFromAcume(currentObj.passedSelectedClient.c_id);
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
            currentObj.spinner = false;
            currentObj.closeModal();
          });
    },
    addOrDeleteUserFromAcume(client_id) {
      let currentObj = this;
      currentObj.isLoading = true;
      currentObj.spinner = true;
      if((currentObj.FilteredSelectedUser).length > 0) {
        (currentObj.FilteredSelectedUser).forEach(function (value, index) {
          currentObj.FilteredSelectedUser[index]['client_id'] = client_id;
          let indexToRemove = -1;
          currentObj.deleteSelectedUser.forEach(function(item, index) {
            if (item.user_id === value.user_id) {
              indexToRemove = index;
            }
          });
          if (indexToRemove !== -1) {
            currentObj.deleteSelectedUser.splice(indexToRemove, 1);
          }
        });
      }
      else {
        currentObj.responseFail('Please select user.');
        currentObj.spinner = false;
        return;
      }
      axios
          .post("/add_acume_users", {'add':this.FilteredSelectedUser,'delete':this.deleteSelectedUser})
          .then(function (response) {
            currentObj.success = response.data.status;
            currentObj.isLoading = false;
            currentObj.spinner = false;
            currentObj.closeModal();
            if (currentObj.success !== true) {
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              currentObj.getClients();
            }
          })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
            currentObj.spinner = false;
            currentObj.closeModal();
          });
    },
    pageReload() {
      this.$router.push('/')
    },
    setFilterUserData(){
      let currentObj = this;
      setTimeout(function () {
        currentObj.FilteredSelectedUser = currentObj.passFilteredSelectedUser;
      },1000);
    },
    deselectUser(user) {
      let indexToRemove = -1;
      this.FilteredSelectedUser.forEach(function(item, index) {
        if (item.user_id === user.user_id) {
          indexToRemove = index;
        }
      });
      if (indexToRemove !== -1) {
        this.FilteredSelectedUser.splice(indexToRemove, 1);
      }
      this.deleteSelectedUser.push(user);
    },
  },
  mounted() {
    console.log('mount......')
  }
}
</script>

<style>
</style>