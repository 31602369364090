import axios from 'axios'
export default
{
    methods:{
        checkUserPermission(task)
        {
            let userPermissions = localStorage.getItem('role_permissions');
            userPermissions = JSON.parse(userPermissions);
            let matched = false;
            if (userPermissions.length > 0) {
                userPermissions.forEach(t=>{
                    if (t.st_name == task){
                        matched = true;
                    }
                });
            }
            return matched;
        },

        responseFail(msg=null) {
            if (msg == null) {
                msg = "There is some problem. Please try again";
            }

            this.$notify({
                title: "Error",
                text: msg,
                type: "error"
            });
        },

        responseSuccess(msg) {
            this.$notify({
                title: "Successful",
                text: msg,
                type: "success",
            });
        }
    }
}