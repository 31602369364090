<template>
  <div id="app">
    <!-- Header -->
    <Header v-if="this.$route.name != 'register' && this.$route.name != 'userverification' && this.$route.name != 'callback' && this.$route.name != 'invitationstatus' && this.$route.name != 'forgotpassword' && this.$route.name != null"/>

    <!-- Body -->
    <div v-if="this.$route.name == 'register' || this.$route.name == 'userverification' || this.$route.name == 'invitationstatus' || this.$route.name == 'callback' || this.$route.name == 'forgotpassword' || this.$route.name == null" :class="(this.$route.name == null ? 'container-fluid bg-grey' : '')">
      <RouterView />
    </div>
    <div v-else class="container-fluid pb-4 custom-background">
      <RouterView />
    </div>

    <!-- Footer -->
    <Footer />

    <!-- Loader -->
    <Loader :is-visible="isLoading" />

    <!-- Tost Notification -->
    <notifications :duration="5000"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Loader,
    Header,
    Footer
  },
  data() {
    return {
      isLoading: false
    }
  }
};
</script>

<style>

</style>
