<template>
  <div class="clientselectmodal">
    <b-modal
        id="modal-select-practice"
        button-size="sm"
        size="md"
        ref="modal"
        title="Select Account"
        ok-only
        ok-variant="blue"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        ok-title="Logout"
        @ok="$auth.logout()"

    >
      <div class="row">
        <div v-for="(data, index) in practice_user" :key="index" class="col-md-6 mb-4">
          <div @click="selectAccount(data.id)" class="custom-pointer bg-light card text-center">
            <div variant="outline-info" class="card-body">
              <font-awesome-icon icon="fa-solid fa-user-circle" size="3x"/>
              <h5>{{ data.organisation_name }}</h5>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Loader -->
    <Loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import {Bus} from "@/main";
import Loader from "./Loader.vue";
import Swal from 'sweetalert2'
import Vue from "vue";

export default {
  name: 'clientselectmodal',
  components: {
    Loader
  },
  inject: ['setLoggedInUser', 'pageRedirect'],
  props: {
    practice_user: "",
    email: ""
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async selectAccount(id) {
      let currentObj = this;
      currentObj.isLoading = true;
      let auth0 = await Vue.prototype.$auth;
      const claims = await auth0.getIdTokenClaims();
      const id_token = claims.__raw;
      currentObj.$root.$emit('bv::hide::modal', 'modal-select-practice');
      axios.post("/login", {practice_id: id, email: currentObj.email,  id_token: id_token}).then(function (response) {
        let loggedInUser = response.data.body[0];
        currentObj.success = response.data.status;
        currentObj.isLoading = false;
        if (currentObj.success !== true) {
          currentObj.$notify({
            group: "alert",
            title: "Error",
            text: "There is some problem. Please try again",
            type: "error"
          });
        } else {
          currentObj.setLoggedInUser(loggedInUser);
        }
      })
          .catch(function (error) {
            currentObj.isLoading = false;
            currentObj.$notify({
              group: "alert",
              title: "Error",
              text: error,
              type: "error"
            });
          });
    }
  }
}
</script>

<style>
.custom-pointer {
  cursor: pointer;
}
</style>