<template>
  <div class="container-fluid bg-grey">
    <div class="row mt-3 col-12">
      <div class="col-2"></div>
      <div class="col-8">
        <b-card title="" sub-title="">
          <b-card-text>
            <div class="col-12 text-center" style="align-self: center;">
              <img src="../assets/acume-secondary-rgb.png" height="100" >
            </div>
            <div v-if="result">
              <div class="col-12 text-center m-4">
                <span class="text-center bold"><h5>{{status == 'approved' ? 'Request Accepted' : 'Request Declined'}}</h5></span>
              </div>
              <div class="col-12 text-center m-4">
                <span class="text-center fw-lighter" v-if="status == 'approved'">Thanks! Your practice invitation has now been accepted successfully, you can now close this tab.</span>
                <span class="text-center fw-lighter" v-else>Thanks for your response, the practice invitation has been declined and Acume has not been linked. You can now close this tab.</span>
              </div>
            </div>
            <div class="col-12 text-center m-4" v-if="noData">
              <span class="text-center bold"><h5>No Data Found</h5></span>
            </div>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-2"></div>
      <!-- Loader -->
      <Loader :is-visible="isLoading" />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "InvitationStatus",
  components: {
    Loader,
  },
  data() {
    return {
      isLoading:false,
      result:false,
      uuid: '',
      status: '',
      noData: false,
    }
  },
  methods: {
    approveDeclineInvitation() {
      let currentObj = this;
      currentObj.isLoading = true;
      // To call api
      axios.get("/approval/client_invitation/" + this.status + "/" + this.uuid)
          .then(function (response) {
            // To check response status is success or fail
            currentObj.isLoading = false;
            if (response.data.status == false) {
              currentObj.noData = true;
              // To return fail response
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              currentObj.noData = false;
              // To return success response
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
              currentObj.result = true;
            }
          })
          .catch(function (error) {
            // To return fail response
            currentObj.noData = true;
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    }
  },
  mounted() {
    this.uuid = this.$route.params.uuid;
    this.status = this.$route.params.status;
    this.approveDeclineInvitation();
  }
}
</script>

<style scoped>
body {

}
</style>