<template>
  <div class="mt-3">
    <div class="row manage-roles">
      <div class="col-md-8 mb-2">
        <h5>Manage Roles</h5>
      </div>
      <div class="col-md-4 mb-2">
        <b-button variant="outline-green" size="sm" class="pull-right" @click="openModal($event)">Add New Role</b-button>
      </div>
    </div>
    <div class="col-md-6 offset-3">
      <h4 style="font-weight:bold; padding-left:0.75rem" class="mb-3">Roles</h4>
      <b-tabs pills v-if="roles.length > 0 && rerender" v-model="tabIndex">
        <b-tab :title="role.name" v-for="role in roles" :key="role.id">
          <tasks :passed_tasks="tasks" :role="role" :id="role.id" :selected_tasks="role.tasks"/>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal id="modal-add-role" ref="modal" :title="modal_title" @ok="saveRole()" ok-variant="blue" size="md">
      <form ref="form">
        <b-form-group
            label="Role Name"
            label-for="name-input"
            invalid-feedback="Role name is required"
        >
          <b-form-input id="name-input" v-model="role_name" placeholder="Role Name" required ref=""></b-form-input>
        </b-form-group>
        <b-form-group
            label="Role Description"
            label-for="name-input"
            invalid-feedback="Role description is required"
        >
          <b-form-input id="desc-input" v-model="role_description" placeholder="Role Description" required></b-form-input>
          <input type="hidden" v-model="role_id"/>
        </b-form-group>
        <b-form-group
            label="Permission"
            label-for="name-input"
            invalid-feedback="Role description is required"
        >
          <v-select multiple v-model="selected" :options="tasks" label="st_title" placeholder="Select Permission" class="border-0"/>
        </b-form-group>
      </form>
    </b-modal>

    <!-- Loader -->
    <Loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Tasks from "./PracticeTasks.vue";

export default {
  name: "ManagePracticeRole",
  components: {
    Loader,
    Tasks
  },
  provide: function () {
    return {
      addUpdateTask: this.addUpdateTask,
      fetchRole: this.fetchRole,
      deleteRole: this.deleteRole
    }
  },
  data() {
    return {
      isLoading: false,
      roles: [],
      tasks: [],
      role_name: "",
      role_description: "",
      role_id: "",
      tabIndex: 0,
      rerender: true,
      modal_title: "New Role",
      selected: [],
      selected_role: [],
      role_task: 0
    };
  },
  methods: {

    openModal(event) {
      this.modal_title = 'New Role';
      this.role_name = '';
      this.role_description = '';
      this.role_id = '';
      this.selected = [];
      this.$root.$emit('bv::show::modal', 'modal-add-role', event.target);
    },
    fetchTasks() {
      let currentObj = this;
      currentObj.isLoading = true;
      this.$store.dispatch("fetch_tasks", {}).then(res => {
        this.tasks = this.$store.getters.tasks;
        currentObj.isLoading = false;
      });
    },
    fetchRoles(action) {
      let ths = this;
      ths.isLoading = true;
      this.$store.dispatch("fetch_roles", {}).then(res => {
        ths.roles = ths.$store.getters.roles;
        if (action == 'load') {
          if (sessionStorage.getItem('key') == null) {
            ths.tabIndex = 0;
          } else {
            ths.tabIndex = parseInt(sessionStorage.getItem('key'));
          }

        } else if (action == 'save') {
          if (sessionStorage.getItem('key') == null) {
            ths.tabIndex = 0;
          } else {
            ths.tabIndex = parseInt(sessionStorage.getItem('key'));
          }
          ths.rerender = false;
          ths.$nextTick(() => {
            // Add the component back in
            ths.rerender = true;
          });
        }
        ths.isLoading = false;
      });
    },
    fetchRole(id, event) {
      let ths = this;
      ths.isLoading = true;
      this.modal_title = 'Edit Role';
      this.$store.dispatch("fetch_role", {"role_id": id}).then(res => {
        ths.role_name = res.data.body.name;
        ths.role_description = res.data.body.description;
        ths.role_id = res.data.body.id;
        ths.selected = res.data.body.tasks;
        ths.isLoading = false;
        this.$root.$emit('bv::show::modal', 'modal-add-role', event.target);
      });
    },
    resetInput() {
      let ths = this;
      ths.modal_title = 'New Role';
      ths.role_name = '';
      ths.role_description = '';
      ths.role_id = '';
      ths.selected = [];
    },
    saveRole() {
      let currentObj = this;
      currentObj.isLoading = true;
      let url = "save_role";
      let ids = [];
      if (currentObj.selected.length > 0) {
        currentObj.selected.filter((item) => {
          ids.push(item.st_id)
        });
      }

      if (currentObj.role_id > 0) {
        url = "update_role"
      }

      currentObj.$store.dispatch(url, {"role_id": currentObj.role_id, "name": currentObj.role_name, "description": currentObj.role_description, "task_ids": ids}).then(response => {
        currentObj.isLoading = false;
        if (response.data.status == false) {
          // To return fail response
          currentObj.$notify({
            title: "Error",
            text: response.data.message,
            type: "error"
          });
        } else {
          currentObj.$notify({
            title: "Success",
            text: response.data.message,
            type: "success"
          });
          sessionStorage.setItem('key', this.tabIndex);
          this.fetchRoles("save");
        }
      });
    },
    addUpdateTask(task_id, role_id, val) {
      let currentObj = this;
      currentObj.isLoading = true;
      this.$store.dispatch("add_task", {"task_id": task_id, "role_id": role_id, "selected": val}).then(response => {
        currentObj.isLoading = false;
        if (response.data.status == false) {
          // To return fail response
          currentObj.$notify({
            title: "Error",
            text: response.data.message,
            type: "error"
          });
        } else {
          // To return success response
          currentObj.$notify({
            title: "Success",
            text: response.data.message,
            type: "success"
          });
          // To get role permission
          this.getUserDetail();
          sessionStorage.setItem('key', this.tabIndex);
          this.fetchRoles("load");
          var trigger = document.querySelector('.nav-pills button');
          if (trigger) {
            trigger.click();
          }
        }
      });
    },
    deleteRole(role_id) {
      let currentObj = this;
      currentObj.isLoading = true;
      this.$store.dispatch("delete_role", role_id).then(response => {
        currentObj.isLoading = false;
        if (response.data.status == false) {
          // To return fail response
          currentObj.$notify({
            title: "Error",
            text: response.data.message,
            type: "error"
          });
        } else {
          // To return success response
          currentObj.$notify({
            title: "Success",
            text: response.data.message,
            type: "success"
          });
          sessionStorage.setItem('key', this.tabIndex);
          this.fetchRoles("load");
          var trigger = document.querySelector('.nav-pills button');
          if (trigger) {
            trigger.click();
          }
        }
      });
    },
    getUserDetail() {
      let currentObj = this;
      currentObj.isLoading = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios.post("/user", {user_id: user.id}).then(function (response) {
        currentObj.success = response.data.status;
        if (currentObj.success !== true) {
          currentObj.isLoading = false;
        } else {
          let loggedInUser = response.data.body;
          let userPermissions = ((loggedInUser.tasks != false) ? loggedInUser.tasks : '');
          localStorage.setItem('role_permissions', JSON.stringify(userPermissions));
          currentObj.isLoading = false;
        }
      })
          .catch(function (error) {
            console.log('error:',error);
          });
    },
  },
  mounted() {
    this.fetchTasks();
    this.fetchRoles("load");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>