<template>
  <div class="container-fluid bg-grey">
    <SelectPracticeModal :practice_user="practice_user" :email="email"/>
    <Loader :is-visible="isLoading"/>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Vue from "vue";
import SelectPracticeModal from "@/components/SelectPracticeModal";
import Swal from 'sweetalert2'

export default {
  name: "Callback",
  components: {
    Loader,
    SelectPracticeModal
  },
  provide: function () {
    return {
      setLoggedInUser: this.setLoggedInUser,
      pageRedirect: this.pageRedirect,
    }
  },
  data() {
    return {
      isAuthenticated: false,
      isLoading: false,
      email: null,
      password: null,
      success: false,
      practice_user: "",
    }
  },
  methods: {
    async getApiToken() {
      let currentObj = this;
      currentObj.isLoading = true;
      let auth0 = await Vue.prototype.$auth;
      let user = await auth0.user;
      const claims = await auth0.getIdTokenClaims();
      const id_token = claims.__raw;
      axios.post("/login", {
        email: user.email,
        id_token: id_token
      })
          .then(function (response) {
            currentObj.success = response.data.status;
            if (currentObj.success !== true) {
              currentObj.isLoading = false;
              Swal.fire({
                title: 'Oops...',
                text: response.data.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,

              }).then((result) => {
                if (result.isConfirmed) {
                  auth0.logout();
                }
              })
            } else {
              if (response.data.body.length > 1) {
                // Multiple practice found
                currentObj.practice_user = response.data.body
                currentObj.email = user.email
                currentObj.isLoading = false;
                currentObj.$root.$emit('bv::show::modal', 'modal-select-practice');
              } else {
                // One practice found
                let loggedInUser = response.data.body[0];
                currentObj.isLoading = false;
                currentObj.setLoggedInUser(loggedInUser);
              }
            }
          })
          .catch(function (error) {
            localStorage.clear();
            currentObj.isLoading = false;
            Swal.fire({
              title: 'Oops...',
              text: error,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,

            }).then((result) => {
              if (result.isConfirmed) {
                auth0.logout();
              }
            })
          });
    },
    pageRedirect() {
      setTimeout(() =>
              this.$router.push({
                path: "/",
              }),
          3000
      );
    },
    setLoggedInUser(loggedInUser) {
      this.isLoading = true;
      let token = loggedInUser.token;
      localStorage.setItem('token', token);
      sessionStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(loggedInUser));
      let userPermissions = ((loggedInUser.task != false) ? loggedInUser.task : '');
      localStorage.setItem('role_permissions', JSON.stringify(userPermissions));
      this.pageRedirect();
    },
    async checkAuthorization() {
      let currentObj = this;
      let auth0 = await Vue.prototype.$auth;
      let isAuthenticated = await Vue.prototype.$auth.isAuthenticated;
      currentObj.isAuthenticated = await isAuthenticated;
      currentObj.isLoading = await Vue.prototype.$auth.isAuthenticated;

      if (!Vue.prototype.$auth.isAuthenticated) {
        currentObj.isLoading = false;
        let errorMsg = auth0.error;
        if (Vue.prototype.$auth.user == undefined) {
          errorMsg = "Please verify your email before logging in.";
        }
        Swal.fire({
          title: 'Oops...',
          text: (errorMsg != null) ? errorMsg : "Authentication Fail!",
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,

        }).then((result) => {
          currentObj.isLoading = false;
          if (result.isConfirmed) {
            auth0.logout();
          }
        })
      } else if (auth0 && auth0.error != null) {
        let errorMsg = auth0.error;
        currentObj.isLoading = false;
        Swal.fire({
          title: 'Oops...',
          text: errorMsg.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,

        }).then((result) => {
          if (result.isConfirmed) {
            auth0.logout();
          }
        })
      } else {
        currentObj.getApiToken();
      }
    }
  },
  created() {
    this.isLoading = true;
  },
  async mounted() {
    setTimeout(async function () {
      this.checkAuthorization();
    }.bind(this), 2000);
  },
};
</script>