<template>
  <div class="row po-header mt-3">
    <div class="col-md-6 offset-3">
      <b-card
          header="Profile"
          header-tag="header"
          header-bg-variant="dark-header"
          header-text-variant="white"
          bg-variant="light"
          style="min-height:350px;"
      >
        <b-card-text>
          <form @submit="updateUser">
            <div class="form-group row">
              <label for="firstName" class="col-sm-4 col-form-label">First Name</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    v-model="user.first_name"
                    placeholder="First Name"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="lastName" class="col-sm-4 col-form-label">Last Name</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    v-model="user.last_name"
                    placeholder="Last Name"
                    required
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col-sm-4 col-form-label">Email</label>
              <div class="col-sm-8">
                <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="user.email"
                    placeholder="Email"
                    disabled
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="user_type" class="col-sm-4 col-form-label">User Role</label>
              <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    id="user_type"
                    v-model="roleName"
                    placeholder="User Role"
                    disabled
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <b-button
                    size="sm"
                    type="submit"
                    variant="green"
                    class="pull-right"
                >Update
                </b-button>
              </div>
            </div>
          </form>
        </b-card-text>
      </b-card>
      <!-- Loader -->
      <Loader :is-visible="isLoading"/>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "profile",
  components: {
    Loader,
  },
  data() {
    return {
      user: {},
      isLoading: false,
      success: false,
      roleName:''
    }
  },
  methods: {
    getUserData() {
      let currentObj = this;
      currentObj.isLoading = true;
      currentObj.user = JSON.parse(localStorage.getItem('user'));
      axios.post("/user", {user_id: currentObj.user.id}).then(function (response) {
        currentObj.success = response.data.status;
        if (currentObj.success !== true) {
          currentObj.isLoading = false;
          currentObj.responseFail(response.data.message);
        } else {
          currentObj.user.first_name = response.data.body.first_name;
          currentObj.user.last_name = response.data.body.last_name;
          currentObj.user.name = response.data.body.name;
          currentObj.roleName = response.data.body.role_name;
          currentObj.isLoading = false;
        }
      }).catch(function (error) {
        currentObj.responseFail(error);
      });
    },
    updateUser(e) {
      e.preventDefault();
      let currentObj = this;
      currentObj.isLoading = true;
      axios.put("/users/" + currentObj.user.id, {
        first_name: currentObj.user.first_name,
        last_name: currentObj.user.last_name,
        name: currentObj.user.first_name + " " + currentObj.user.last_name,
      }).then(function (response) {
        currentObj.success = response.data.status;
        currentObj.isLoading = false;
        if (currentObj.success !== true) {
          currentObj.responseFail(response.data.message);
        } else {
          currentObj.responseSuccess(response.data.message);
          currentObj.user.name = response.data.body.name;
          localStorage.setItem('user', JSON.stringify(currentObj.user));
          setTimeout(() => {
            document.location.reload();
          }, 2000);
        }
      }).catch(function (error) {
        currentObj.isLoading = false;
        currentObj.responseFail(error);
      });
    },
  },
  created() {
    this.getUserData();
  }
};
</script>

