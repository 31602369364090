<template>
  <div class="container-fluid bg-grey d-flex p-5">
    <div class="col-8" style="align-self: center">
      <img src="../assets/acume-small-reverse.png">
    </div>
    <div class="col-4" style="align-self: center">
      <b-card title="" header-tag="header">
        <template #header>
          <h4 slot="header" type="dark" variant="dark">REGISTER</h4>
        </template>
        <b-card-body class="landing-card pl-0 pr-0">
          <form @submit="onSubmit" class="add-form">
            <div class="form-group">
              <label>Practice Name</label>
              <input type="text" id="practiceName" name="practiceName" v-model="practiceName" class="form-control" placeholder="Practice Name" required>
            </div>
            <div class="form-group">
              <label>Owner Email</label>
              <input type="email" id="email" name="email" v-model="email" class="form-control" placeholder="Owner Email" required>
            </div>
            <div class="form-group">
              <label>First Name</label>
              <input type="text" id="firstName" name="firstName" v-model="firstName" class="form-control" placeholder="First Name" required>
            </div>
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" id="lastName" name="lastName" v-model="lastName" class="form-control" placeholder="Last Name" required>
            </div>
            <div class="form-group">
              <label>Registration Key</label>
              <input type="text" id="registrationKey" name="registrationKey" v-model="registrationKey" class="form-control" placeholder="Registration Key" required>
            </div>
            <div class="form-group text-center">
              <b-button type="submit" class="mt-1 col-12" variant="grey" block>Register</b-button>
            </div>
          </form>
        </b-card-body>
        <b-card-footer type="dark" variant="dark">
          <div class="row">
            <div class="col-md-12 text-center">
              <router-link to="/">Back to Login</router-link>
            </div>
          </div>
        </b-card-footer>
      </b-card>
    </div>

    <!-- Loader -->
    <Loader :is-visible="isLoading" />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import {VUE_APP_API_SERVER_URL} from "../../auth_config.json";

export default {
  name: "RegisterView",
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: false,
      practiceName: '',
      email: '',
      firstName: '',
      lastName: '',
      registrationKey: '',
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      let currentObj = this;
      currentObj.isLoading = true;
      // To call api
      axios.post(VUE_APP_API_SERVER_URL+"/signup", {
        "organisation_name": currentObj.practiceName,
        "email": currentObj.email,
        "first_name": currentObj.firstName,
        "last_name": currentObj.lastName,
        "registration_key": currentObj.registrationKey,
        "name": currentObj.firstName + " " + currentObj.lastName
      })
          .then(function (response) {
            // To check response status is success or fail
            currentObj.isLoading = false;
            if (response.data.status == false) {
              // To return fail response
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              currentObj.$notify({
                title: "Success",
                text: response.data.message,
                type: "success"
              });
              // To return success response and redirect to login
              currentObj.$router.push('/');
            }
          })
          .catch(function (error) {
            // To return fail response
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    },
    changePassword(e) {
      // e.preventDefault();
      let currentObj = this;
      currentObj.isLoading = true;
      // To call api
      axios.post(VUE_APP_API_SERVER_URL+"/changepassword", {
        "email": currentObj.email,
      })
          .then(function (response) {
            // To check response status is success or fail
            currentObj.isLoading = false;
            if (response.data.status == false) {
              // To return fail response
              currentObj.$notify({
                title: "Error",
                text: response.data.message,
                type: "error"
              });
            } else {
              // To return success response and redirect to login
              currentObj.$router.push('/');
            }
          })
          .catch(function (error) {
            // To return fail response
            currentObj.isLoading = false;
            currentObj.$notify({
              title: "Error",
              text: error,
              type: "error"
            });
          });
    }
  }
}
</script>

<style scoped>

</style>