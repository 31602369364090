<template>
  <div v-if="emailTemplateDetail != ''">
    <b-modal
        id="modal-email-template"
        ref="modal"
        size="lg"
        button-size="sm"
        :title="emailTemplateDetail.template_subject"
        ok-title="Save"
        @ok="updateEmailDetail(emailTemplateDetail.id, emailTemplateDetail.status)"
        ok-variant="green"
    >
      <form>
        <div class="form-group row">
          <label for="email-subject" class="col-sm-2 col-form-label">Subject :</label>
          <div class="col-sm-10">
            <input ref="subject_area" counter label="subject_area" type="text" class="form-control" id="email-subject" :value="emailTemplateDetail.template_subject" @focus="current_cursor='subject'">
          </div>
        </div>
        <div class="form-group row">
          <label for="email-subject" class="col-sm-2 col-form-label"></label>
          <div class="col-6 d-flex">
            <select v-model="insertText" class="form-control type-select col-8 float-left mr-1 border-grey">
              <option value="">Select</option>
              <option v-for="option in (emailTemplateDetail.template_attribute)" :key="option.value" v-bind:value="option.value">
                {{ option.name }}
              </option>
            </select>
            <b-button size="sm" variant="secondary" @click="execInsertText">Add</b-button>
          </div>
        </div>
        <div class="form-group row">
          <label for="email-body" class="col-sm-2 col-form-label">Body :</label>
          <div class="col-sm-10">
            <textarea ref="text_area" counter label="text_area" class="form-control" id="email-body" rows="10" :value="emailTemplateDetail.template_body" @focus="current_cursor='body'"></textarea>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from "vuex"

export default {
  name: 'emailtemplatemodal',
  props: ['emailTemplateDetail'],
  data() {
    return {
      insertText: "",
      current_cursor: "body",
    }
  },
  computed: {
    ...mapGetters(["allEmailTemplate", "emailTemplate"])
  },
  methods: {
    ...mapActions(["fetchEmailTemplates", "updateEmail"]),

    updateEmailDetail(id, status) {
      let currentObj = this;
      currentObj.isLoading = true;
      const params = {
        id: id,
        template_subject: document.getElementById('email-subject').value,
        template_body: document.getElementById('email-body').value
      }

      currentObj.updateEmail(params).then(res => {
        currentObj.isLoading = false;
        if (res.data.status == true) {
          currentObj.$notify({
            group: 'alert',
            title: 'Successful',
            text: res.data.message,
            type: 'success'
          });
          currentObj.fetchEmailTemplates();
          currentObj.insertText = "";
        } else {
          currentObj.$notify({
            title: "Error",
            text: res.data.message,
            type: "error"
          });
        }
      }).catch(err => {
        currentObj.isLoading = false;
        currentObj.$notify({
          group: 'alert',
          title: 'Error',
          text: err,
          type: 'error'
        });
      });
    },
    execInsertText() {
      const insertText = this.insertText
      let textarea
      if (!insertText.length) return
      if (this.current_cursor == "subject") {
        textarea = this.$refs.subject_area
      } else {
        textarea = this.$refs.text_area
      }

      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }
      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)
      if (this.current_cursor == "subject") {
        this.emailTemplateDetail.template_subject = before + insertText + after
      } else {
        this.emailTemplateDetail.template_body = before + insertText + after
      }
      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length
      })
    }
  }
}
</script>

<style>

</style>