<template>
 <div>
   <b-table
       responsive
       head-variant="dark"
       id="my-table"
       :items="users"
       :fields="fields"
       :per-page="perPage"
       :current-page="currentPage"
       :sort-by.sync="$parent.sortBy"
       :sort-desc.sync="$parent.sortDesc"
       @sort-changed="sortingChanged"
       show-empty
   >
     <template v-slot:cell(action)="row">
       <b-button
           variant="blue"
           size="sm"
           :to="{ path: '/edit-practice-user/'+row.item.user_id}"
           class="mr-1"
       >Edit
       </b-button>
       <b-button
           variant="orange"
           size="sm"
           @click="deleteUser(row.item.user_id)"
           class="mr-1"
           v-if="($parent.loggedInUserEmail != row.item.email)"
       >Delete
       </b-button>
     </template>

     <template v-slot:cell(name)="row">
       <span class="text-nowrap">{{ row.item.name }}</span>
     </template>
     <template v-slot:cell(status)="row">
       <span class="text-nowrap">{{ (row.item.status == 1) ? 'Active' : 'Inactive' }}</span>
     </template>
   </b-table>
 </div>
</template>

<script>
export default {
  name: "UserList",
  props: ['users', 'fields', 'sortBy', 'sortDesc', 'perPage', 'currentPage', 'totalPage', 'recordsTotal', 'recordsFiltered', 'loggedInUserEmail'],
  inject:['sortingChanged','deleteUser'],
}
</script>

<style scoped>

</style>